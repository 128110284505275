import axiosCustom from './customAxios';
import { PackageSuggestionRequest, PackageSuggestion } from './models';
const path = 'get-suggestion/';

export const getSuggestedPlan = async (
  body: PackageSuggestionRequest,
): Promise<PackageSuggestion> => {
  const { age, activity_level, dgwght, food_percent, breed } = body;
  const { data, error } = await axiosCustom.get(
    `${path}?age=${age}&activity_level=${activity_level}&dgwght=${dgwght}&food_percent=${food_percent}&breed=${breed}`,
  );
  if (error) {
    return error;
  }
  return data;
};
export const getSuggestedPlan2 = async (body: any
): Promise<PackageSuggestion> => {
  const { body_type, activity_level, dgwght, age, food_percent, breed, invite_code } = body;
  const queryParams = new URLSearchParams({
    age: age,
    activity_level: activity_level,
    dgwght: dgwght,
    body_type: body_type,
    food_percent: food_percent || '100',
    breed: breed || '',
  });

  // If invite_code exists, append it to the query params
  if (invite_code) {
    queryParams.append('invite_code', invite_code);
  }

  const { data, error } = await axiosCustom.get(`${path}?${queryParams.toString()}`);
  if (error) {
    return error;
  }
  return data;
};
export const sendSuggestedPlanEmail = async (
  payload,
): Promise<PackageSuggestion> => {
  const { data, error } = await axiosCustom.post('send-suggestion/', payload);
  if (error) {
    return error;
  }
  return data;
};