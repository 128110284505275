import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import Chicken from '../../../assets/svg/chicken.svg';
import Lamb from '../../../assets/svg/lamb.svg';
import PlanToggleButton from './components';
import CdBox from './product';
import LdBox from './lambProduct';
import tick from './tick.svg';
import MoreInfo from './components/moreinfo';

type Props = {
  onSelect: (selectedPlan: Plan) => void;
  subscription_plans: any;
  activeProduct: any;
  nextStep: any;
  voucher: any;
  cdb_details: any;
  ldb_details: any;
};

const Plans = ({
  onSelect,
  subscription_plans,
  activeProduct,
  nextStep,
  voucher,
  cdb_details,
  ldb_details
}: Props) => {
  const newPlan = (subscription_plans || []).reduce((result, item) => {
    (result[item.type] = result[item.type] || []).push(item);
    return result;
  }, {});
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);

  // Function to update the selected plan state
  const handlePlanSelect = (plan: Plan) => {
    setSelectedPlan(plan);
    onSelect(plan); // Pass selected plan to parent component
  };

  return (
    <div className={styles.plansListContainer}>
      {Object.keys(newPlan).map((it: any, index: string) => {
        return (
          <span key={'cdb-box' + index}>
            {
              <Plan
                activeProduct={activeProduct}
                onSelect={handlePlanSelect}
                plans={newPlan[it]}
                nextStep={nextStep}
                index={index}
                voucher={voucher}
                cdb_details={cdb_details}
                ldb_details={ldb_details}
                showMessage={subscription_plans.length > 0}
                subscriptionPlans={subscription_plans}
              />
            }
          </span>
        );
      })}
    </div>
  );
};

const propsList = [
  'Grain Free',
  'All-natural ingredients',
  'High protein at 28%',
  'Batch made in NZ',
  // '10% off on first delivery',
  'Easily edit delivery frequency',
  'Subscriber only discounts on pet essentials',
];
interface Plan {
  name: string;
  calories_per_day: number;
  food_per_day: number;
  frequency: number;
  actual_frequency: number;
  price_per_day: string;
  fortnight_charge: string;
  cups_per_day: number;
  box_count: number;
  message: string;
  product: number;
  orginal_product_price: number;
  pet_id: null | number; // Depending on whether it can be null or not
  product_price: number;
  price_per_delivery: number;
  orginal_price: number;
  quantity: number;
  default: boolean;
  discount_price: number;
  type: boolean;
}
const Plan = React.memo(
  ({
    onSelect,
    plans,
    isOneOff = false,
    activeProduct,
    nextStep,
    index,
    showMessage,
    voucher,
    cdb_details,
    ldb_details,
    userSelected,
    subscription_plans

  }: {
    onSelect: (selectedPlan: Plan) => void;
    plans: Plan;
    isOneOff?: boolean;
    activeProduct: any;
    nextStep: any;
    index: number;
    showMessage: boolean;
    voucher: any;
    cdb_details: any;
    ldb_details: any;
    userSelected: any;
    subscription_plans: any;
  }) => {
    const {
      price_per_day,
      frequency,
      name,
      product,
      orginal_product_price,
      bestValue,
      product_price,
      orginal_price,
      price_per_delivery,
      discount_price,
    } = plans || {};
    const [selectedIndex, setSelectedIndex] = useState(0);
    useEffect(() => {
      if (plans && plans.length > 0 && selectedIndex === null) {
        const lastIndex = plans.length - 1;
        setSelectedIndex(lastIndex);
        onSelect(plans[lastIndex]);
      }
    }, [plans, selectedIndex]);
    
    
    const item = plans[selectedIndex];

    const handlePlanSelect = (index: number) => {
      setSelectedIndex(index);
      const item = plans[index]; // Update selected plan
      onSelect(item); // Call onSelect function with the selected plan
    };
    return (
      <div
        className={`${styles.planContainer} ${
          activeProduct === product ? styles.isActive : ''
        }`}
      >
        <div className={`${styles.titleBox}`}>
          <span>
            {' '}
            {item.type == 'cdb' ? 'Chicken Dog Box' : 'Dog Food Luxe Lamb'}
          </span>
        </div>
        {item.type == 'cdb' ? (
          <div className={`${styles.bestValue} font-popins`}>Premium</div>
        ) : (
          <div className={`${styles.bestValue} font-popins`}>Super Premium</div>
        )}
        <div className={`${styles.titleBoxContentWrap}`}>
          <div
            className={`${styles.DailyReqWrap} ${item.type == 'cdb' ? styles.chickenBox : styles.lambBox } d-flex `}
          >
            <div className={`${styles.DailyMeatNameBox} ${item.type == 'cdb' ? styles.chickenSvg : styles.lambSvg } d-flex`}>
              <div>
                <img
                  height={25}
                  width={25}
                  src={item.type == 'cdb' ? Chicken : Lamb}
                />
              </div>
              <p className={`${styles.MeatText}`}>
                {item.type == 'cdb' ? 'Chicken' : 'Lamb'}
              </p>
            </div>
            <div className={`${styles.DailyMeatRight} d-flex`}>
              <div className={`${styles.DailyReq} font-popins`}>
                Daily Requirement
              </div>
              <div className={`${styles.DailyWeight} font-popins`}>
                {item.cups_per_day} {item.cups_per_day == 1 ? 'cup' : 'cups'} = {item.food_per_day} g/day
              </div>
            </div>
          </div>
          <div>
            <PlanToggleButton
              planOptions={plans}
              // onChange={index => {
              //   setSelectedIndex(index);
              // }}
              onChange={(index: number) => {
                handlePlanSelect(index); // Update selected plan when toggled
              }}
              selectedIndex={selectedIndex}
            />
            <div className={`${styles.prizePerdaySub} font-popins`}>
              <span className={styles.highlight}>{item.name}</span> delivered every {item.frequency} days
            </div>
          </div>

          <div className={`${styles.imagebox}`}>
            <div className={`${styles.moreInfoImgWrap}`}>
            {item.type == 'cdb' ? <CdBox /> : <LdBox />}
              <div className={`${styles.moreInfoWrap}`}>
                <MoreInfo 
                  itemType={item.type}
                  cdbDetails={cdb_details}
                  ldbDetails={ldb_details}
                />
              </div>
            </div>
            <div className={`${styles.prizePerdayWrap}`}>
              <h3 className={`${styles.prizePerday}`}>${item.price_per_day}</h3>
              <p>a day</p>
            </div>
          </div>

          <div className={`${styles.notableTextWrap} font-popins`}>
            <p className={`${styles.RepDeliveryRate}`}>
              Your repeat delivery price:{' '}
              {/* <span className={`${styles.StrikeThrough}`}> */}
                ${item.orginal_price}
              {/* </span> */}
            </p>
            { voucher ? <div className={`${styles.discBoxWrap} d-flex`}>
                <img height={16} width={16} src={tick} />
                <div className={`${styles.discBoxText}`}>
                First order {voucher} discount applied
                </div> 
              </div> : <div> </div>}
          </div>
          <div className={`${styles.Purchasebox} font-popins`}>
            <div
              className={`${styles.oneTimePurchaseRow} d-flex align-items-center justify-content-between`}
            >
              <div className={`${styles.oneTimePurchaseText}`}>
                One Time Purchase
              </div>
              <div className={`${styles.oneTimePurchaseValue}`}>
                ${item.product_price}
              </div>
            </div>
            <div
              className={`${styles.SubscBoxWrap} d-flex align-items-center justify-content-between`}
            >
              <div className={`${styles.SubscTextWrap}`}>
                Subscribe
                <span className={`${styles.discText}`}>( Saves ${item.discount_price.toFixed(2)})</span>
              </div>
              <div className={`${styles.SubscValue}`}>
                ${item.price_per_delivery}
              </div>
            </div>
            <div
              className={`${styles.selectBtnWrap} d-flex justify-content-center`}
            >
              <button
                onClick={() => {
                  onSelect(item.product);
                  nextStep();
                }}
                type="button"
                className="btn-def extended button-placer"
              >
                Select
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  },
);

export default Plans;
