import React, { useState, useEffect } from 'react';
import { graphql, navigate } from 'gatsby';
import { BasketLine, PetList, OrderType, HomePageOfferData, Pet } from 'models';
import FrequencyModal from '../components/FrequencyModal';
import { isLoggedIn } from '../services/auth.api';
import { OptimizedImage } from '../components/common/OptimizedImage';

import {
  getAllPetsList,
  updateAllPetsList,
  updatePetFrequency,
  isBrowser,
  trackCheckout,
  STEP_CONTINUE_CHECKOUT,
  updateCheckoutState,
} from '../utils';

import { checkHomePageOffer } from 'services/voucher.api';
import { trackInitiateCheckout, trackLead } from 'services/facebook.api';
import { removeLine, updateLine, useAppDispatch, useAppSelector } from 'state';
import FeedingPlan2 from 'components/FeedingPlan2';
import Loader from 'components/PetSuggestion/Loader';

interface FrequencyState {
  frequency: number;
  quantity: number;
  edited: boolean;
}

export default ({ location, ...props }: { location: any }) => {
  if (isLoggedIn()) {
    navigate('/account/dashboard/');
  }
  const { data } = props;
  const { allRestApiApiProducts } = data;
  const allProducts = (allRestApiApiProducts?.nodes || []).map(it => {
    const id = it.endpointId;
    // delete it.endpointId;
    return { ...it, id };
  });
  const dispatch = useAppDispatch();
  const basket = useAppSelector(state => state.basket.basket);
  const products = useAppSelector(state => state.basket.products);

  const {
    dogsList = [],
    suggestion = {},
    petDetails = {},
    userFirstName,
    email,
    isExistingUser = false,
  } = location.state || {};
  const currentList = getAllPetsList() || dogsList;
  const [show, showFrequencyModal] = useState(false);

  const [basketLineToEdit, setBasketLineToEdit] = useState({} as BasketLine);
  const [petToEdit, setPetToEdit] = useState({} as any);
  const [prevLocation, setPrevLocation] = useState('');
  const [petsList, setPetsList] = useState(currentList || []);
  const [loader, setLoader] = useState(false);
  const [frequencyState, setFrequencyState] = useState({
    frequency: suggestion.frequency,
    quantity: suggestion.box_count,
    edited: false,
  } as FrequencyState);

  const [isBackButtonClicked, setBackButtonPress] = useState(false);
  const [homePageOffer, setHomePageOffer] = useState({} as HomePageOfferData);

  const checkHPOffer = async () => {
    const apiResponse = await checkHomePageOffer();
    setHomePageOffer(
      apiResponse.data ? apiResponse.data : ({} as HomePageOfferData),
    );
  };

  useEffect(() => {
    checkHPOffer();
    if (!isBrowser()) return;
    trackLead(location.pathname, email);

    window.history.pushState(
      window.history.state,
      '',
      window.location.pathname,
    );
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const navigateBack = () => {
    navigate('/getstarted', {
      state: {
        dogsList: getAllPetsList(), //updatedDogsList,
        edit: true,
      },
    });
  };
  const onBackButtonEvent = (e: PopStateEvent) => {
    e.preventDefault();
    if (!isBackButtonClicked) {
      navigateBack();
    }
  };

  useEffect(() => {
    const prev = location.state?.referrer;
    prev && setPrevLocation(prev);
  }, [location.state]);

  const updateDetails = async ({
    frequency,
    quantity,
    reset,
    petData,
    lineItem,
  }: {
    frequency: number;
    quantity: number;
    reset: boolean;
    petData: PetList;
    lineItem?: BasketLine;
  }) => {
    const editedLineId = basketLineToEdit.id || lineItem?.id;
    if (basket?.id && editedLineId) {
      await dispatch(
        updateLine({
          lineId: editedLineId,
          frequency,
          quantity,
        }),
      );
    }
    setFrequencyState({
      frequency,
      quantity,
      edited: reset,
    });

    const currentPet = petData || petToEdit;
    const updatedPetList = updatePetFrequency(
      currentPet?.petDetails?.id as number,
      frequency,
      petsList,
    );
    setPetsList(updatedPetList);
    return true;
  };

  const updatePetId = (currentPetId: number) => {
    let updatedList: PetList[] = [];
    petsList.map((item: PetList) => {
      const newItem = { ...item };
      const data = newItem.petDetails;
      if (data.name === petDetails.name) {
        if (!newItem.petDetails.id) {
          newItem.petDetails = { ...newItem.petDetails, id: currentPetId };
        }
        newItem.suggestion = {
          ...newItem.suggestion,
          ...frequencyState,
        };
      }
      updatedList.push(newItem);
    });
    return updatedList;
  };

  const navigateToCheckout = async (data, isSample = false) => {
    try {
      const updatedDogsList = data; //updatePetId(currentPetId);
      // updateAllPetsList(data);
      const { suggestion, petDetails } = data[data.length - 1];
      trackInitiateCheckout(
        location.pathname,
        basket?.total_incl_tax as number,
        email || '',
      );

      if (!isLoggedIn()) {
        trackCheckout(STEP_CONTINUE_CHECKOUT, products);
      }
      let checkoutState = {};
      const staticData = {
        allProducts,
        frequency: suggestion.frequency,
        dogsName: petDetails.name,
        total: basket?.total_incl_tax,
        basketUrl: basket?.url,
        isExistingUser: isExistingUser,
        userFirstName: '',
        email: '',
        totalExcludingDiscounts: basket?.total_incl_tax_excl_discounts,
      };
      //ot query param is only used to distinguish url for google analytics
      if (isSample) {
        checkoutState = {
          ...staticData,
          boxCount: 1,
          isTester: true,
        };
      } else {
        checkoutState = {
          ...staticData,
          boxCount: suggestion.box_count,
          dogsList: updatedDogsList,
        };
      }
      updateCheckoutState(checkoutState);
      navigate(`/checkout?ot=${OrderType.New}`, {
        state: checkoutState,
      });
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    } catch (e) {
      setLoader(false);
    }
  };

  const addAnotherDog = async (currentPetId: number) => {
    (async () => {
      //ot query param is only used to distinguish url for google analytics
      // const updatedDogsList = [...dogsList];
      const updatedDogsList = updatePetId(currentPetId);
      updateAllPetsList(updatedDogsList);
      navigate('/getstarted', {
        state: {
          basket,
          dogsList: updatedDogsList,
          userData: {
            userFirstName,
            email,
          },
        },
      });
    })();
  };
  const addAnotherDog2 = async (currentPetId: number) => {
    (async () => {
      navigate('/getstarted', {
        state: {
          dogsList: getAllPetsList(), //updatedDogsList,
        },
      });
    })();
  };
  const removePet = async (
    lineId: number,
    petInfo: Pet,
    currentPetId: number,
  ) => {
    await dispatch(removeLine(lineId));
    let updatedDogsList = updatePetId(currentPetId);
    const petId = petInfo?.id || currentPetId;
    const petIndex = updatedDogsList.findIndex(
      item => item.petDetails.id === petId,
    );
    updatedDogsList.splice(petIndex, 1);
    updateAllPetsList(updatedDogsList);
    setPetsList(updatedDogsList);
    return;
  };

  const handleDeliveryFrequencyUpdate = async (
    petData: PetList,
    lineItem: BasketLine,
    reset?: boolean,
  ) => {
    setBasketLineToEdit(lineItem);
    setPetToEdit(petData);

    if (reset) {
      await updateDetails({
        frequency: petData.suggestion.original_suggested_frequency || 0,
        quantity: petData.suggestion.box_count,
        reset: false,
        petData,
        lineItem,
      });
    } else {
      setFrequencyState({
        frequency: petData.suggestion.frequency,
        quantity: petData.suggestion.box_count,
        edited: false,
      });
      showFrequencyModal(true);
    }
    return true;
  };

  if (
    (getAllPetsList() || []).length === 0 ||
    !petDetails ||
    Object.keys(petDetails || {}).length === 0
  ) {
    // Someone visiting the URL directly
    isBrowser() && navigate('/');
    return null;
  } else {
    if (loader)
      return (
        <Loader
          list={[
            {
              label1: 'Creating Pet',
              // label2: "Almost there! We're piecing together the insights...",
            },
            {
              label1: 'Adding products to cart',
              // label2: "Almost there! We're piecing together the insights...",
            },
            {
              label1: 'Adding products to cart',
              // label2: "Almost there! We're piecing together the insights...",
            },
          ]}
        />
      );

    return (
      <div style={{ position: 'relative' }}>
        <div style={{ position: 'static' }}>
          <div className="page-wrapper">
            <FrequencyModal
              show={show}
              closeModal={() => showFrequencyModal(false)}
              boxCount={frequencyState.quantity}
              frequency={frequencyState.frequency}
              suggestedFrequency={suggestion.frequency}
              saveDetails={updateDetails}
              suggestion={suggestion}
            />
            <div className="signup-wrapper bg-off-white">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-5 col-hide-md">
                    <div className="su-img-wrapper su-prod-wrapper pos-rel">
                      <OptimizedImage fileName="food_prod.png" />
                    </div>
                  </div>
                  <div className="col-12 pos-rel col-lg-7">
                    <div className="margin-top-15">
                      <FeedingPlan2
                        isModal={false}
                        allProducts={allProducts}
                        petDetails={petDetails}
                        suggestion={suggestion}
                        handleDeliveryFrequencyUpdate={
                          handleDeliveryFrequencyUpdate
                        }
                        frequencyState={frequencyState}
                        updateDetails={updateDetails}
                        emailingOptionEnabled={false}
                        handleOrderSubmit={navigateToCheckout}
                        handleAddNewFB={addAnotherDog2}
                        existingDogsList={petsList}
                        removePet={removePet}
                        prevRoute={prevLocation}
                        fromParent="getStarted"
                        homePageOffer={homePageOffer}
                        edited={show}
                        userData={{
                          email,
                          userFirstName,
                        }}
                        handleLoader={(flag = false) => {
                          setLoader(flag);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
export const query = graphql`
  query {
    allRestApiApiProducts {
      nodes {
        title
        id
        endpointId
        image
        is_public
        is_subscribable
        is_suggestible
        items_in_cart_limit
        percentage
        price {
          currency
          excl_tax
          incl_tax
          tax
        }
        product_attributes {
          code
          name
          value
        }
        product_class
        product_type
        shipping_charge
        slug
        subscription_price
        weightage
        url
        description
        categories {
          id
          name
          slug
          ancestors {
            id
            name
            slug
          }
          descendants {
            id
            name
            slug
          }
        }
      }
      totalCount
    }
  }
`;
