import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import IngredientsText from './IngredientsText';
import DescriptionText from './DescriptionText';
import NutritionsText from './NutritionsText';

export const MobileAccordian: React.FC = ({ itemType, cdbDetails, ldbDetails }) => {
  const [openSection, setOpenSection] = useState<string | undefined>(undefined);

  const toggleAccordion = (eventKey: string) => {
    setOpenSection(openSection === eventKey ? undefined : eventKey);
  };

  return (
    <div className="accrdian-wrap more-info-accordian">
      <Accordion activeKey={openSection} onSelect={toggleAccordion}>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="0">
              Description
              {openSection === '0' ? <BsChevronUp /> : <BsChevronDown />}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <DescriptionText 
                description={itemType == 'cdb' ? cdbDetails?.description : ldbDetails?.description}
              />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="1">
              Ingredients
              {openSection === '1' ? <BsChevronUp /> : <BsChevronDown />}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <IngredientsText 
                ingredients_description={itemType == 'cdb' ? cdbDetails?.nutritional_description : ldbDetails?.nutritional_description}
              />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="2">
              Nutritions
              {openSection === '2' ? <BsChevronUp /> : <BsChevronDown />}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              <NutritionsText 
                nutritions={itemType == 'cdb' ? cdbDetails : ldbDetails}
              />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
};

export default MobileAccordian;
