import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

interface ButtonLoaderProps {
  text: string;
  loadingText?: string;
  onClick?: Function;
  loading: boolean;
  disabled?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
}

const ButtonLoader: React.FC<ButtonLoaderProps> = (props, ref) => {
  return (
    <Button
      className={props.className}
      type={props.type}
      onClick={props.onClick ? props.onClick : () => {}}
      disabled={props.disabled || props.loading}
      ref={ref}
    >
      {props.loading && (
        <>
          <Spinner
            as="span"
            animation="grow"
            role="status"
            aria-hidden="true"
          />
          <span>{props.loadingText ? props.loadingText : props.text}</span>
        </>
      )}
      {!props.loading && props.text}
    </Button>
  );
};

export default React.forwardRef(ButtonLoader);
