import React, { useState } from 'react';
function DescriptionText({description}: {description: any}) {

  return (
    <>
      <p dangerouslySetInnerHTML={{ __html: description }}/>

    </>
  );
};

export default DescriptionText;
