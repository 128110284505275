import axiosCustom from './customAxios';
import { Pet } from './models';

const path = 'pet/';

export const getAllPets = async (): Promise<Pet[]> => {
  const { data, error } = await axiosCustom.get(path);
  if (error) return error;
  return data;
};

export const createPet = async (body: Pet): Promise<Pet> => {
  const { data, error } = await axiosCustom.post(path, body);
  if (error) throw error;
  return data;
};
export const createPet2 = async (body: any): Promise<any> => {
  const { data, error } = await axiosCustom.post(path, body);
  if (error) throw error;
  return data;
};
export const getPet = async (petId: number) => {
  const { data, error } = await axiosCustom.get(`${path}${petId}/`);
  return data;
};

export const updatePet = async (
  petId: number,
  petDetails: Pet,
): Promise<Pet> => {
  const { data, error } = await axiosCustom.put(`${path}${petId}/`, petDetails);
  if (error) throw error;
  return data;
};

export const deletePet = async (petId: number) => {
  const { data, error } = await axiosCustom.delete(`${path}${petId}/`);
  if (error) throw error;
  return data;
};
