import React, { useState } from 'react';
import {
  Product,
  ProductAttributeValues,
  ProductInfo,
} from '../../../services/models';
import { isProductOfAttributeValue } from '../../../utils';
import styles from './style.module.scss';

type Props = {
  allProducts: any;
  addOrRemoveAddon: any;
  activeAddons: Number[];
};

const Addons = ({ allProducts, addOrRemoveAddon, activeAddons }: Props) => {
  const isPet = true;
  const [limit, setLimit] = useState(8);
  const addonProducts = allProducts.filter(
    (item: any) =>
      item.is_public &&
      (isProductOfAttributeValue(item, ProductAttributeValues.ADD_ON) ||
        isProductOfAttributeValue(item, ProductAttributeValues.ONE_OFF)) &&
      item?.is_suggestible,
  );
  return (
    <div>
      <div className={styles.addonsList}>
        {addonProducts.map((item: any, index: any) => {
          if (index < limit) {
            return (
              <Addon
                key={'addon-' + index}
                data={item}
                isAdded={(activeAddons || []).includes(item.id)}
                addOrRemoveAddon={addOrRemoveAddon}
              />
            );
          }
          return <></>;
        })}
      </div>
      {limit < addonProducts.length ? (
        <div className={`${styles.showMore} font-popins`}>
          <span
            onClick={() => {
              setLimit(prev => prev + 8);
            }}
            className="cur-pointer"
          >
            Show me more options{' '}
          </span>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const Addon = ({
  data,
  isAdded,
  addOrRemoveAddon,
}: {
  data: any;
  isAdded: boolean;
  addOrRemoveAddon: Function;
}) => {
  const {
    id,
    title,
    image,
    price: { incl_tax },
  } = data;
  return (
    <div className={`${styles.addonItem} ${isAdded ? styles.active : ''}`}>
      <div className={styles.image}>
        <img src={image} />
      </div>
      <div className={styles.title}>{title}</div>
      <div className={`${styles.price} font-popins`}>{incl_tax}</div>
      <div className={styles.button}>
        <button
          onClick={() => {
            addOrRemoveAddon(id);
          }}
          className={`btn-small ${isAdded ? styles.remove : ''}`}
        >
          {isAdded ? 'Remove' : 'Add to cart'}
        </button>
      </div>
    </div>
  );
};

export default Addons;
