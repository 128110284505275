import React, { useState } from 'react';
import styles from '../style.module.scss';
import SwitchSelector from 'react-switch-selector';

const options = [
  {
    label: '2.5kg',
    value: null,
    fontColor: '#2e2e2e',
    selectedBackgroundColor: '#2e2e2e',
    selectedFontColor: '#ffffff',
  },
  {
    label: '5kg',
    value: null,
    fontColor: '#2e2e2e',
    selectedBackgroundColor: '#2e2e2e',
    selectedFontColor: '#ffffff',
  },
];

const PlanToggleButton = ({ planOptions, onChange, selectedIndex }) => {
  // const onChange = newValue => {
  //   console.log(newValue);
  // };
  const options = (planOptions || []).map((plan, index) => ({
    label: plan.name,
    value: index,
    fontColor: '#2e2e2e',
    selectedBackgroundColor: '#2e2e2e',
    selectedFontColor: '#ffffff',
  }));
  // const initialSelectedIndex = options.findIndex(({ value }) => value === null);
  return (
    <div className="d-flex justify-content-center">
      <div className="BoxSizeMentionWrap">
        {/* <div className={`${styles.toggleButtonTitle} font-popins`}>
          Choose Your box size:
        </div> */}
        <div className="d-flex justify-content-center Switch-select-wrap">
          <SwitchSelector
            inputName="fooBar"
            onChange={onChange}
            options={options}
            initialSelectedIndex={selectedIndex}
            backgroundColor={'#353b48'}
            fontColor={'#f5f6fa'}
          />
        </div>
      </div>
    </div>
  );
};

export default PlanToggleButton;
