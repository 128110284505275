import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import close from '../assets/close.png';
import { FrequencyUpdate } from './FrequencyUpdate';

interface Props {
  closeModal: () => void;
  saveDetails: Function;
  show: boolean;
  boxCount: number;
  frequency: number;
  suggestedFrequency: number;
  suggestion:any;
}

const FrequencyModal: React.FC<Props> = props => {
  const [isSubmitting, setSubmittingStatus] = useState(false);
  const handleSubmit = values => {
    setSubmittingStatus(true);
    props.saveDetails({ ...values, reset: true }).then(() => {
      setSubmittingStatus(false);
      props.closeModal();
    });
  };

  return (
    <Modal show={props.show} onHide={props.closeModal}>
      <Modal.Body>
        <div className="cust-modal-content-wrapper">
          <div className="modal-close pos-rel">
            <img onClick={e => props.closeModal()} src={close} />
          </div>
          <div className="cust-modal-content">
            <FrequencyUpdate
              onSubmit={handleSubmit}
              boxCount={props.boxCount}
              frequency={props.frequency}
              isSubmitting={isSubmitting}
              suggestedFrequency={props.suggestedFrequency}
              suggestion={props.suggestion}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FrequencyModal;
