import React, { useState } from 'react';
const NutritionsText = (props: any) => {
  const {protein, fat, fiber, calcium, phosphorus, moisture} = props.nutritions;
  return (
    <div>
      <div className="nutrition-row d-flex">
        <div className="nutrition-col nutrition-col-left">Crude Protein </div>
        <div className="nutrition-col nutrition-col-right">min {protein}%</div>
      </div>

      <div className="nutrition-row d-flex">
        <div className="nutrition-col nutrition-col-left">Crude Fat</div>
        <div className="nutrition-col nutrition-col-right">min {fat}%</div>
      </div>
      <div className="nutrition-row d-flex">
        <div className="nutrition-col nutrition-col-left">Calcium</div>
        <div className="nutrition-col nutrition-col-right">{calcium}%</div>
      </div>
      <div className="nutrition-row d-flex">
        <div className="nutrition-col nutrition-col-left">Crude Fibre</div>
        <div className="nutrition-col nutrition-col-right">max {fiber}%</div>
      </div>
      <div className="nutrition-row d-flex">
        <div className="nutrition-col nutrition-col-left">Moisture</div>
        <div className="nutrition-col nutrition-col-right">max {moisture}%</div>
      </div>
      <div className="nutrition-row d-flex">
        <div className="nutrition-col nutrition-col-left">Phosphorus</div>
        <div className="nutrition-col nutrition-col-right">{phosphorus}%</div>
      </div>
    </div>
  );
};

export default NutritionsText;
