import React from 'react';
import AppConstants from '../AppConstants';
import { FrequencyUpdateForm } from './FrequencyUpdateForm';
import {  productList } from '../../src/utils';

interface Props {
  boxCount: number;
  frequency: number;
  onSubmit: Function;
  isSubmitting?: boolean;
  statusMessage?: string;
  suggestedFrequency?: number;
  suggestion:any;
}

export const FrequencyUpdate: React.FC<Props> = props => {
  return (
    <>
      <div className="hdr text-center">
        <h2 className="mgn-bot-40">Edit delivery frequency</h2>
        <p>Based on your personalised feeding plan, you'll require</p>
        <p>
          <label>
            {props.boxCount}x{' '}
            {props.suggestion
              ? productList.find(
                  it => Number(it.value) === props.suggestion?.product,
                )?.label
              : AppConstants.PRODUCTS.CHICKEN_DOG_BOX.name}{' '}
            every {props.frequency} days.
          </label>
        </p>
        <p>
          However, if you'd prefer to choose your own delivery frequency you can
          do so below.
        </p>
      </div>

      <FrequencyUpdateForm
        boxCount={props.boxCount}
        frequency={props.frequency}
        onSubmit={props.onSubmit}
        isSubmitting={props.isSubmitting}
        statusMessage={props.statusMessage}
      />
    </>
  );
};
