import React, { useEffect, useState } from 'react';
import styles from '../../style.module.scss';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from './close.svg';
import Chicken from '../../../../../assets/svg/chicken.svg';
import Lamb from '../../../../../assets/svg/lamb.svg';
import MobileAccordian from './components/mobileAccordian';
import IngredientsText from './components/IngredientsText';
import DescriptionText from './components/DescriptionText';
import NutritionsText from './components/NutritionsText';

export const MoreInfo = ({ itemType, cdbDetails, ldbDetails }) => {
  const propsList = [
    'Grain Free',
    'All natural ingradients',
    'High protein 28%',
    'Batch made in NZ',
    'Edit delivery frequency',
    'Subscriber only discounts on pet essentials',
  ];

  useEffect(() => {}, []);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const isMobileDevice = () => {
    return (
      typeof window.orientation !== 'undefined' ||
      navigator.userAgent.indexOf('IEMobile') !== -1
    );
  };

  return (
    <div>
      <div className={styles.infoContainer} onClick={handleShow}>
        <div className={`${styles.moreInfo} font-popins`}>More Info</div>
      </div>
      {/* Desktop Popup */}
      {!isMobileDevice() && (
        <Modal
          show={show}
          onHide={handleClose}
          centered
          dialogClassName="moreinfo-pop-wrap"
        >
          <Modal.Body className="moreinfo-pop-body">
            <div className={`d-flex justify-content-between ${itemType == 'cdb' ? 'moreinfo-chicken-pop-header' : 'moreinfo-lamb-pop-header'}`}>
              <div className="d-flex align-items-center">
                <div className={`d-flex flex-column align-items-center ${itemType == 'cdb' ? 'chicken-icon-box' : 'lamb-icon-box'} justify-content-center`}>
                  <div>
                    <img src={itemType == 'cdb' ? Chicken : Lamb} alt="" />
                  </div>
                  <div className="chicken-text">{itemType == 'cdb' ? 'Chicken' : 'Lamb'}</div>
                </div>
                <h3>{itemType == 'cdb' ? 'Chicken' : 'Lamb'} Dog Box</h3>
              </div>
              <span onClick={handleClose} className="more-info-close-btn">
                {' '}
                <img src={CloseIcon} alt="" />
              </span>
            </div>

            <div className="moreinfo-content">
              <div className="description-box">
                <h4>Description</h4>
                <DescriptionText 
                  description={itemType == 'cdb' ? cdbDetails?.description : ldbDetails?.description}
                />
              </div>
              <div className="d-flex mt-40">
                <div className="description-box double-box-left">
                  <h4>Ingredients</h4>
                  <IngredientsText 
                    ingredients_description={itemType == 'cdb' ? cdbDetails?.nutritional_description : ldbDetails?.nutritional_description}
                  />
                </div>
                <div className="description-box nutrition-box">
                  <h4>Nutritions</h4>
                  <NutritionsText 
                    nutritions={itemType == 'cdb' ? cdbDetails : ldbDetails}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {/* Mobile Popup */}
      {isMobileDevice() && (
        <Modal
          show={show}
          onHide={handleClose}
          centered
          dialogClassName="moreinfo-pop-wrap"
        >
          <Modal.Body className="moreinfo-pop-body">
            <div className={`d-flex justify-content-between ${itemType == 'cdb' ? 'moreinfo-chicken-pop-header' : 'moreinfo-lamb-pop-header'}`}>
              <div className="d-flex align-items-center">
                <div className={`d-flex flex-column align-items-center ${itemType == 'cdb' ? 'chicken-icon-box' : 'lamb-icon-box'} justify-content-center`}>
                  <div>
                    <img src={itemType == 'cdb' ? Chicken : Lamb} alt="" />
                  </div>
                  <div className="chicken-text">{itemType == 'cdb' ? 'Chicken' : 'Lamb'}</div>
                </div>
                <h3>{itemType == 'cdb' ? 'Chicken' : 'Lamb'} Dog Box</h3>
              </div>
              <span onClick={handleClose} className="more-info-close-btn">
                {' '}
                <img src={CloseIcon} alt="" />
              </span>
            </div>
            <div>
              <MobileAccordian 
                itemType={itemType}
                cdbDetails={cdbDetails}
                ldbDetails={ldbDetails}
              />
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default MoreInfo;
