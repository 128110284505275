import React, { useState } from 'react';
const IngredientsText = ({ingredients_description}: {ingredients_description: any}) => {
  return (
    <>
      <p dangerouslySetInnerHTML={{ __html: ingredients_description }}/>
    </>
  );
};

export default IngredientsText;
